
import { defineComponent, ref } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Icon from '@/components/icon/Icon';
import IconComponent from '@/components/icon/IconComponent.vue';

export default defineComponent({
  components: {
    ButtonComponent,
    IconComponent
  },
  props: {
    error: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const email = ref();
    const password = ref();

    function signIn() {
      emit('login', {
        email: email.value,
        password: password.value
      });
    }

    return {
      email,
      Icon,
      password,
      signIn
    };
  }
});
