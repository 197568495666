import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center py-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_component = _resolveComponent("login-component")

  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode(_component_login_component, {
      onLogin: _ctx.login,
      error: _ctx.error,
      isLoading: _ctx.isLoading
    }, null, 8, ["onLogin", "error", "isLoading"])
  ]))
}